export const DateFields = {};

export const newBranding = true; // process.env.BRANDING === 'new';

export const imgBaseUrl = 'https://assets.holmanfm.com/img/';

export const Logo = `${imgBaseUrl}${
  newBranding ? 'new/Holman-Logo-Blue' : 'Holman'
}.svg`;

export const LogoWhite = `${imgBaseUrl}${
  newBranding ? 'new/Holman-Logo-White.svg' : 'Holman-Logo-white.png'
}`;

export const AccountStatus = {
  TRY: 'TRY',
  FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD',
  CONFIRMED: 'CONFIRMED',
};

export const Persistent = {
  ID_TOKEN_NAME: 'id-jwt',
  ACCESS_TOKEN_NAME: 'access-jwt',
  REFRESH_TOKEN_NAME: 'refresh-jwt',
  STORE: 'lf-store',
  persistentStates: ['cart', 'orgs'],
};

export const ReduxConstants = {
  NEW_ID_PREFIX: 'toBeAdded',
};

// %%%%%%%%%%%%%%%%%%%%%%%%%% MAP %%%%%%%%%%%%%%%%%%%%%%%%%%

export const MapItemType = {
  VEHICLE: 'Vehicle',
  NEARBY_VEHICLE: 'NEARBY_VEHICLE',
  DEVICE: 'DEVICE',
};

export const STATUS = {
  GOOD: 'good',
  WARN: 'WARN',
  BAD: 'bad',
  EMPTY: 'EMPTY',
  THREE_QUARTERS: 'THREE_QUARTERS',
  QUARTER: 'QUARTER',
  NULL: 'NULL',
  IN_TRANSIT: 'IN_TRANSIT',
  IN_STOCK: 'IN_STOCK',
  ON_HOLD: 'ON_HOLD',
  YESTERDAY: 'YESTERDAY',
  TODAY: 'TODAY',
  LONGER: 'LONGER',
  NOT_CONNECTED: 'NOT CONNECTED',
  NOT_RESPONDING: 'NOT RESPONDING',
  OFF_LOT: 'OFF_LOT',
  ON_LOT: 'ON_LOT',
  UNKNOWN_LOCATION: 'UNKNOWN_LOCATION',
};

export const PAYMENT_STATUSES = {
  PENDING_MANUAL_VERIFICATION: 'pending_manual_verification',
  PENDING_AUTOMATIC_VERIFICATION: 'pending_automatic_verification',
  MANUALLY_VERIFIED: 'manually_verified',
  OK: 'ok',
  BANK_ACCOUNT: 'bank_account',
  TEMPORARY: '__TEMPORARY__',
  AUTOMATICALLY_VERIFIED: 'automatically_verified',
};

export const NULL_AS_STRING = '__null__';

// export const ExperianCreditForm = 'https://www.commercialcreditapps.com/BusinessIQ/credec/caps/pla/loadAppInternalPLAPage?addEditFlag=A&appTemplateId=x1ZR9MojE5Z6S322lh6bNg%3D%3D&appTemplateToken=9198';
// temporary until we get an emdeddable Experian url
export const ExperianCreditForm = 'https://www.google.com/webhp?igu=1';

export const FEDEX_CLIENT_ID = '006576';

export const DEFAULT_HOMEPAGE_ID = 'available-services';

export const OLD_HOMEPAGES = {
  AvailableServices: 'available-services',
  BrowseStock: 'shop-stock-inventory',
  DriverTraining: 'driver-training-assignments',
  ShopVehiclesContainer: 'marketplace-shop-vehicles',
  TelematicsDashboard: 'telematics-dashboard',
  ParentOrgManagement: 'parent-org',
};

export const ANNUAL_MILEAGE_DISPLAY_VALUES = [
  {
    value: 0,
    label: '0 - 15,000',
  },
  {
    value: 1,
    label: '15,001 - 25,000',
  },
  {
    value: 2,
    label: '25,001 - 35,000',
  },
  {
    value: 3,
    label: '35,001 - 50,000',
  },
  {
    value: 4,
    label: '> 50,000',
  },
];

export const displayAnnualMileageLabel = annualMileageValue =>
  ANNUAL_MILEAGE_DISPLAY_VALUES.find(
    entry => entry?.value === annualMileageValue
  )?.label;

export const HSBS = 'HSBS';
export const HRS = 'HRS';
